@import "../common";
.full-green-background {
    @include respond-to-min($desktopSize) {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            right:-99%;
            height: 100%;
            width: 100vw;
            top: 0;
            background-color: #8cc026;
            z-index: -1;
        }
    }

}
.out-story {

    .our_story_rooted_in_past {
        padding: $gutter;
        h2 {
            font-size: 3.2rem;
            font-weight: 600;
            margin-bottom: $gutter * 0.5;
            strong {
                font-weight: 600;
            }
        }
        p {
            font-weight: 500;
            font-size: $font-size--medium;
        }

        .image {
            @include respond-to-min($desktopSize) {
                display: flex;
                align-items: flex-start;
                padding-top: 8px;
            }
        }

        @include respond-to-max($desktopSize) {
            .row > div {
                padding: 0;
            }
        }
    }

    .our-story-delight {
        background: $primary-color;

        @include respond-to-max($maxMobileContainerSize) {
            width: 100vw;
            margin-top: $gutter * 2;
            margin-bottom: $gutter * 2;
        }
        @include respond-to-min($maxMobileContainerSize) {
            margin: $gutter * 3 0;
            width: 100vw;
            --padding-story: #{$gutter  * 0.3};
            @include only-touch-device {
                --padding-story: #{$gutter  * 0.25};
            }
            transform: translateX(calc((100vw - var(--full-width)) / -2 - var(--padding-story)));
        }

        .image {
            position: relative;
            img {
                width: 100%;
                bottom: 0;
                z-index: 0;
                margin-top: -13%;
            }

            p {
                z-index: 1;
                position: absolute;
                line-height: 1;
                text-align: right;
                width: fit-content;
                top: 15%;
                left: 5%;
                span {
                    display: block;
                    &:nth-child(1) {
                        margin-bottom: -0.5rem;
                    }
                    &:nth-child(2) {
                        color: $white;
                    }
                }
                @include respond-to-min($defaultDesktopSize) {
                    font-size: 3.8rem;
                    span {
                        display: block;
                        &:nth-child(1) {
                            padding-right: $gutter * 1.5;
                        }
                        &:nth-child(2) {
                            font-size: 8.2rem;
                        }
                    }
                }
                @include respond-to-min-max($laptopSize, $defaultDesktopSize) {
                    font-size: 3.4rem;
                    span {
                        &:nth-child(1) {
                            padding-right: $gutter * 1.2;
                        }
                        &:nth-child(2) {
                            font-size: 7.3rem;
                        }
                    }
                }
                @include respond-to-min-max($tabletSize, $laptopSize) {
                    font-size: 3.8rem;
                    span {
                        &:nth-child(1) {
                            padding-right: $gutter * 1.5;
                        }
                        &:nth-child(2) {
                            font-size: 8.2rem;
                        }
                    }
                }
                @include respond-to-min-max($tabletSize, $desktopSize) {
                    font-size: 3.4rem;
                    span {
                        &:nth-child(2) {
                            font-size: 7.6rem;
                        }
                    }
                }
                @include respond-to-max($tabletSize) {
                    font-size: 2.3rem;
                    span {
                        &:nth-child(1) {
                            padding-right: $gutter * 0.75;
                        }
                        &:nth-child(2) {
                            font-size: 4.4rem;
                        }
                    }
                }
            }
        }

        .content {
            @include respond-to-max($desktopSize) {
                padding: $gutter;
            }
            @include respond-to-min($desktopSize) {
                padding: $gutter * 2;
            }

            p {
                color: $white;
                font-weight: 500;
                margin: 0;
                strong {
                    font-size: 3.2rem;
                    color: $black;
                    line-height: 1;
                    font-weight: 600;
                    display: inline-block;
                }
            }
        }
    }
}


.display-table{
    display: table;
    height: 100%;
    .display-table-bottom{
        display: table-cell;
        vertical-align: bottom;
    }
    .display-table-middle{
        display: table-cell;
        vertical-align: middle;
    }
}
